import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/best-time-to-buy-a-house-in-san-diego-market-analysis-tips"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Is Now the Right Time to Buy a House in San Diego_.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                To say the San Diego housing market is "hot" would be a massive understatement.
                Rising home prices, competitive bidding, and low inventory are causing many
                prospective buyers to wonder: Is now the right time to buy a house in San Diego?{" "}
              </CommonParagraph>
              <CommonParagraph>
                While we believe there are still plenty of options out there, we want to give you an
                honest and fully transparent look at the market so you can decide for yourself.
                Here, we'll give you an overview of the area’s current real estate trends and
                provide a few critical questions to ask yourself before diving headfirst into the
                market.
              </CommonParagraph>
              <Heading type="h2">Current State of the Market</Heading>
              <CommonParagraph>
                <strong>Time on Market:</strong> Currently, San Diego homes on the market sell
                within 15 days and receive an average of six offers.
              </CommonParagraph>
              <CommonParagraph>
                <strong>Competition:</strong> The average San Diego home sells about 1% above the
                listing price. Competitive or "hot homes" (reasonably priced and in desirable
                locations) are selling for as much as 5% above the listing price.
              </CommonParagraph>
              <CommonParagraph>
                <strong>Cost:</strong> The median home sale price is $921,753, up 8.4% from last
                year. Many of these homes sold above the listing price, with waived contingencies.
              </CommonParagraph>
              <CommonParagraph>
                <strong>Homes Sold:</strong> 857 homes were sold in April, up from 787 in 2023.
              </CommonParagraph>
              <CommonParagraph>
                <strong>Inventory:</strong> As of May, 2,903 homes were on the market, a 13.3%
                increase from April.
              </CommonParagraph>
              <CommonParagraph>
                <strong>Current Interest Rates:</strong> Currently, a 30-year fixed-rate mortgage is
                at 7.06%
              </CommonParagraph>
              <CommonParagraph>
                Considering the market’s current state, here are a few questions to consider before
                you start shopping for a new San Diego home.
              </CommonParagraph>
              <Heading type="h2">Do You Currently Own a Home?</Heading>
              <CommonParagraph>
                If you already own a home and have a low-interest rate locked in, you may want to
                proceed with caution. At a current 7.06% interest rate, you would likely face a
                significantly higher interest rate than your current mortgage; this could increase
                your monthly payments and overall cost of borrowing.
              </CommonParagraph>
              <CommonParagraph>
                However, we also realize that some prospective buyers have outgrown their homes,
                simply need to relocate, or may have the finances to comfortably purchase a new home
                at a higher interest rate.
              </CommonParagraph>
              <Heading type="h2">Are You Feeling Competitive?</Heading>
              <CommonParagraph>
                Homes in San Diego sell for as much as 5% over the asking price and receive as many
                as six offers. So, if you find a home you love, you may have to offer more than the
                listing price and compete with other bidders. You may have to waive contingencies or
                offer a higher price to stand out.
              </CommonParagraph>
              <Heading type="h2">Are you Ready to Stay in One Place?</Heading>
              <CommonParagraph>
                While San Diego home prices are rising at an unprecedented rate (which means your
                property value could appreciate significantly), it is still essential to consider
                your long-term plans. Investigating closing costs and realtor commissions could take
                time to offset the cost of buying and selling a home.{" "}
              </CommonParagraph>
              <CommonParagraph>
                You should also consider capital gains taxes. If the home appreciates and you sell
                it before owning it for two years, you may face capital gains taxes on the profit
                from the sale.
              </CommonParagraph>
              <Heading type="h2">Are You Financially Ready?</Heading>
              <CommonParagraph>
                A mortgage is a big commitment. Down payment requirements vary by lender, but you
                should expect to put down 3.5-10% on your new house. With the current median San
                Diego home selling for $921,753, that down payment could be anywhere from $32,261 to
                $92,175. In addition to the down payment, you'll also need to budget for closing
                costs, which typically range from 2-5% of the loan amount.
              </CommonParagraph>
              <Heading type="h2">What Does Your Credit Score Look Like?</Heading>
              <CommonParagraph>
                In general, lenders prefer credit scores of 740 and above. If your credit score is
                in the 500s-600s, you could still qualify for a loan but may have fewer lending
                options and may have to pay a higher interest rate.
              </CommonParagraph>
              <Heading type="h2">Competitive Markets Demand Competitive Realtors</Heading>
              <CommonParagraph>
                Thinking about buying in San Diego? Then you will need a trusted ally by your side.
                <BlogLink url="https://selbysellssd.com/" text="The Selby Team" /> understands the
                local market and can give you the insight and guidance you need to stay competitive
                while making informed decisions that fit your lifestyle and goals.{" "}
                <ContactSlideoutLink text="Connect with us" /> today to get started!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
